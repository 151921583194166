.navbar {
    .navbar-toggler {
        border: none;
        box-shadow: none;
        padding-inline: 0;
    }
    .navbar-nav {
        align-items: center;
        gap: rem(10px);
        @include media-breakpoint-up(lg) {
            gap: rem(4px);
        }

        .nav-link {
            color: $white;
            text-transform: uppercase;
            position: relative;
            padding: rem(8px 20px);
            @include media-breakpoint-up(lg) {
                padding: rem(10px 20px);
            }
            &::before {
                content: "";
                position: absolute;
                bottom: rem(5px);
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: rem(3px);
                background-color: $white;
                border-radius: $border-radius-sm;
                transition: width 0.3s, opacity 0.3s;
                @include media-breakpoint-up(lg) {
                    bottom: 0;
                }
            }
            &.active {
                &:before {
                    opacity: 1;
                    width: 100%;
                }
            }
            &:hover {
                cursor: pointer;
                color: $white;
                text-decoration: underline;
            }
            &-tag-new {
                &::after {
                    content: "NOVINKA";
                    font-size: rem(8px);
                    color: $white;
                    background-color: $success;
                    border-radius: $border-radius-sm;
                    padding: rem(2px 4px);
                    position: absolute;
                    top: -5px;
                    right: -3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            &.dropdown-toggle {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    height: 28px;
                    width: 28px;
                    background: $black;
                    border-radius: 50%;
                    overflow: hidden;
                    padding: 5px;
                }
            }
        }
    }
}
