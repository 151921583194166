main {
  section {
    &.error {
      padding: rem(120px 0);

      .text-container {
        max-width: rem(440px);
        margin: 0 auto;
      }
    }
  }
}