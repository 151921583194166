main {
    section {
        &.login {
            padding: rem(120px 0);

            .login-container {
                max-width: rem(440px);
                margin: 0 auto;
                text-align: center;

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                .or {
                    position: relative;
                    line-height: 1;

                    hr {
                        margin: rem(40px 0);
                    }
                }

                .text:not(.text-small) {
                    color: $gray;
                    position: absolute;
                    display: flex;
                    text-align: center;
                    left: 50%;
                    top: 50%;
                    line-height: 1;
                    transform: translate(-50%, -50%);
                    padding: rem(4px 8px 8px 8px);
                    background: $white;
                }

                .text-small {
                    transform: translate(-50%, -50%);
                    font-size: $extra-small-font-size;
                    color: $gray;
                    line-height: 1;
                    bottom: rem(30px);
                    z-index: 1;
                    pointer-events: none;
                    left: 50%;
                    @include media-breakpoint-down(md) {
                        bottom: calc(50% - rem(4px));
                    }
                }

                input[type="number"] {
                    -moz-appearance: textfield;
                }

                .btn {
                    width: 100%;
                }

                h1 {
                    text-transform: uppercase;
                    margin-bottom: rem(14px);
                }

                .tootlit-container {
                    justify-content: center;
                    text-align: center;
                    //display: inline-flex;
                    flex-wrap: wrap;
                    line-height: 1;
                    align-items: center;
                    gap: rem(4px);
                    margin-bottom: rem(24px);

                    p,
                    span {
                        font-size: $large-font-size;
                        line-height: 1.2;
                        display: inline;
                        margin-bottom: 0;
                    }

                    span {
                        border-bottom: 1px dotted $black;
                    }
                }
            }
        }
    }
}
