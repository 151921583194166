@use "sass:math";
@import "sass-rem";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$mediaPath: "../../assets/";

$theme-colors: (
    "primary":
        darken(
            $color: #092c70,
            $amount: 10%,
        ),
    "secondary": #535353,
    "success": #198754ff,
    "info": #0dcaf0ff,
    "warning": #ffc107ff,
    "danger": #dc3545ff,
    "light": #f8f9faff,
    "dark": #212529ff,
);

$border-radius: 0.5rem;
$border-radius-sm: $border-radius;
$border-radius-lg: $border-radius;
$border-radius-xl: $border-radius;
$border-radius-2xl: $border-radius;
$border-radius-pill: 50rem;

$gradient: linear-gradient(to right, #050f22, #082359, #050f22);

$primary: #082359;
$secondary: #535353;
$divider: #a0a0a0;
$white: #ffffff;
$gray: gray;

$large-font-size: rem(18px);
$small-font-size: rem(14px);
$extra-small-font-size: rem(12px);

$font-family-base: "Poppins", sans-serif;

$h1-font-size: rem(42px);
$h2-font-size: rem(28px);
$h3-font-size: rem(20px);
$h4-font-size: rem(18px);

$headings-font-weight: 500;

.Toastify__toast {
    border-radius: 0 !important;
}

@keyframes spinnerAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.loading-box-global {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: rem(30px);
    background-color: $white;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    gap: rem(32px);
    justify-content: center;
    align-items: center;
    text-align: center;
    animation: spinnerAnimation 0.3s;

    .spinner-border-custom {
        width: 5rem;
        height: 5rem;
        border-width: 0.5em;
    }
}

.small-icon {
    width: rem(24px);
    height: rem(24px);
}

.xsmall-icon {
    width: rem(22px);
    height: rem(22px);
}

.small-title {
    display: flex;
    align-items: center;
    gap: rem(4px);
    font-size: rem(12px);
    margin-bottom: rem(4px);
    font-weight: 500;
    .xsmall-icon {
        width: rem(16px);
        height: rem(16px);
    }
}

.mr-4 {
    margin-right: rem(40px);
}

.accordion-disabled {
    pointer-events: none;
    .accordion-button {
        & > div {
            gap: rem(10px 20px);
        }
        pointer-events: none;
        &::after {
            display: none;
        }
    }
}

.accordion-edit {
    .accordion-header {
        .accordion-button {
            & > div {
                gap: rem(10px 20px);
            }
            &::after {
                display: none;
            }
            .btn {
                &:after {
                    content: "Zrušit";
                }
                .icon-times {
                    display: block;
                }
                .icon-edit {
                    display: none;
                }
            }
            &.collapsed {
                .btn {
                    &:after {
                        content: "Upravit";
                    }
                    .icon-times {
                        display: none;
                    }
                    .icon-edit {
                        display: block;
                    }
                }
            }
        }
    }
}
