main {
    .documents {
        hr {
            margin-top: rem(4px);
            opacity: 1;
            border: none;
            width: 100%;
            height: 4px;
            background: $gradient;
            border-radius: $border-radius-sm;
        }

        padding: rem(40px 0);

        .signed-item {
            border-radius: $border-radius-sm;
            border: 1px solid $success;
            padding: rem(20px);
            background-color: lighten($color: $success, $amount: 65%);

            .title-perex {
                display: block;
                font-size: $small-font-size;
                color: $gray;
            }

            .time {
                display: block;
                margin-bottom: rem(18px);
                font-size: rem(12px);
            }

            & ~ .signed-item {
                margin-top: rem(20px);
            }
        }

        .ref-item {
            border: 1px solid $border-color;
            padding: rem(20px);
            border-radius: $border-radius-sm;

            @include media-breakpoint-down(md) {
                padding: rem(10px);
            }

            .title-perex {
                display: block;
                font-size: $small-font-size;
                color: $gray;
            }

            .document-item {
                height: 100%;
                padding: rem(20px);
                background: $info-bg-subtle;
                border-radius: $border-radius-sm;
                border: 1px solid $info-border-subtle;
                position: relative;

                &-not-signed {
                    background: $info-bg-subtle;
                    border: 1px solid $info-border-subtle;
                    &::after {
                        position: absolute;
                        content: "Dokument čeká na podepsání";
                        background-color: $danger-bg-subtle;
                        border: 1px solid $danger-border-subtle;
                        color: $danger-bg-subtle-dark;
                        font-size: rem(12px);
                        padding: rem(4px 8px);
                        border-radius: $border-radius-sm;
                        font-weight: $font-weight-semibold;
                        max-width: rem(120px);
                        top: -20px;
                        right: 0;
                        transform: none;

                        @include media-breakpoint-up(md) {
                            transform: translateX(50%);
                        }
                    }
                }

                @include media-breakpoint-down(md) {
                    padding: rem(10px);
                }

                .value-box {
                    //border: 1px solid $light;
                    background: $white;
                    padding: rem(18px 10px);
                    border-radius: $border-radius-sm;

                    label {
                        font-weight: $headings-font-weight;
                    }

                    .side-values {
                        display: block;
                        font-size: $small-font-size;
                        color: $gray;
                    }

                    .info-text {
                        margin-top: rem(12px);
                        display: block;
                        font-size: $small-font-size;

                        & ~ .info-text {
                            margin-top: rem(4px);
                        }

                        &.allValue {
                            width: 100%;
                            border-top: 1px solid $light;
                            padding-top: rem(8px);
                            margin-top: rem(8px);
                        }
                    }
                }
            }

            & ~ .ref-item {
                margin-top: rem(20px);
            }
        }
    }
}
