main {
    .home {
        hr {
            margin-top: rem(4px);
            opacity: 1;
            border: none;
            width: 100%;
            height: 4px;
            background: $gradient;
            border-radius: $border-radius-sm;
        }

        padding: rem(40px 0);

        .accordion-heading {
            padding: rem(4px 20px);
            border-bottom: 1px solid #dee2e6;
            margin-bottom: rem(18px);
            @include media-breakpoint-down(md) {
                padding: rem(4px 10px);
                h3 {
                    font-size: $small-font-size;
                }
            }
        }

        .qrcodem {
            justify-content: flex-end;

            fieldset {
                max-width: rem(180px);
            }
        }

        .accordion {
            .accordion-item {
                .accordion-body {
                    fieldset {
                        border-radius: $border-radius-sm;
                        margin-top: rem(14px);
                        position: relative;
                        padding: rem(12px 10px 10px 10px);
                        border: 1px solid $primary;
                        width: 100%;

                        legend {
                            font-size: $small-font-size;
                            position: absolute;
                            left: 20px;
                            top: -12px;
                            background: $white;
                            width: fit-content;
                            padding: rem(0 8px);
                        }

                        img {
                            z-index: 11;
                            width: 100%;
                        }
                    }

                    tr {
                        td {
                            &:first-child {
                                font-weight: bold;
                            }
                        }
                    }

                    td {
                        padding-right: rem(10px);
                        padding-bottom: rem(10px);
                    }
                }

                .accordion-header {
                    .accordion-button {
                        width: 100%;
                        display: flex;

                        @include media-breakpoint-down(md) {
                            padding: rem(10px 20px 10px 10px);
                            &:after {
                                right: 5px !important;
                                width: 14px;
                                height: 14px;
                                background-size: 14px;
                                //display: none;
                            }
                            h4 {
                                font-size: $small-font-size;
                            }
                        }

                        @include media-breakpoint-down(lg) {
                            @include media-breakpoint-up(md) {
                                padding-right: rem(64px);
                            }
                        }

                        &::after {
                            position: absolute;
                            right: 20px;
                            top: 50%;
                            transform: translateY(-50%) rotate(-180deg);
                        }

                        &.collapsed {
                            &::after {
                                transform: translateY(-50%) rotate(0);
                            }
                        }

                        & > div {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
