.button {
  outline: none;
  border: none;
  box-shadow: none;
  text-decoration: none;
  display: inline-flex;
  align-items: center;

  &-bankid {
    font-family: "Poppins", sans-serif;
    background-color: #000000;
    color: #ffffff;
    font-weight: 600;
    padding: rem(12px 18px);
    line-height: 1;
    border-radius: 8px;
    font-size: rem(14px);

    svg {
      padding-right: 10px;
      border-right: 1px solid #ffffff;
      margin-right: 10px;
      width: 80px;
    }
  }
}
